import React from "react"
import Container from "../components/new-design/Container"
import Layout from "../components/new-design/Layout"
import Seo from "../components/seo"
import Block from "@sanity/block-content-to-react"
import { GatsbyImage } from "gatsby-plugin-image"
import { FaChevronLeft } from "react-icons/fa"
import { Link } from "gatsby"
export default function Blog({ pageContext: { data }, location }) {
  const metaImage = {
    src: data.image ? data.image.asset.url : null,
    width: data.image ? data.image.asset.metadata.dimensions.width : null,
    height: data.image ? data.image.asset.metadata.dimensions.height : null,
  }

  return (
    <Layout>
      <Seo
        image={metaImage}
        title={data.name}
        // description={data.frontmatter.description}
        pathname={location.pathname}
      />
      <section className="py-12 flex flex-col gap-4">
        <Container>
          <div className="flex flex-col gap-4">
            <Link to="/blog" className="flex gap-2 items-center">
              <FaChevronLeft />
              <span>Back</span>
            </Link>
            <h1>{data.title || data.name}</h1>
            {data.description && (
              <p className="max-w-4xl mx-auto italic">{data.description} </p>
            )}
          </div>
        </Container>
        <Container>
          {data.image && (
            <GatsbyImage
              image={data.image.asset && data.image.asset.gatsbyImageData}
              alt={data.name}
              className="rounded-2xl h-[450px] w-full object-cover"
            />
          )}
        </Container>
        <Container>
          <Block
            blocks={data._rawContent}
            className="flex flex-col gap-5 py-6"
          />
        </Container>
      </section>
    </Layout>
  )
}
